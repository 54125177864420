@font-face {
  font-family: "CINZEL bold";
  src: url("assets/fonts/Cinzel-Bold.ttf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: FUTURA;
  src: url("assets/fonts/FuturaPTDemi.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "FUTURA bold";
  src: url("assets/fonts/FuturaPTHeavy.otf") format("opentype");
  font-style: bold;
}
@font-face {
  font-family: THEALIENS;
  src: url("assets/fonts/Cinzel-Regular.ttf") format("truetype");
  font-style: normal;
}
